// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-apply-tsx": () => import("./../../../src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-candidate-en-tsx": () => import("./../../../src/pages/candidate.en.tsx" /* webpackChunkName: "component---src-pages-candidate-en-tsx" */),
  "component---src-pages-candidate-he-tsx": () => import("./../../../src/pages/candidate.he.tsx" /* webpackChunkName: "component---src-pages-candidate-he-tsx" */),
  "component---src-pages-client-tsx": () => import("./../../../src/pages/client.tsx" /* webpackChunkName: "component---src-pages-client-tsx" */),
  "component---src-pages-company-survey-tsx": () => import("./../../../src/pages/company-survey.tsx" /* webpackChunkName: "component---src-pages-company-survey-tsx" */),
  "component---src-pages-freelancer-survey-tsx": () => import("./../../../src/pages/freelancer-survey.tsx" /* webpackChunkName: "component---src-pages-freelancer-survey-tsx" */),
  "component---src-pages-frontend-questions-tsx": () => import("./../../../src/pages/frontend-questions.tsx" /* webpackChunkName: "component---src-pages-frontend-questions-tsx" */),
  "component---src-pages-hire-tsx": () => import("./../../../src/pages/hire.tsx" /* webpackChunkName: "component---src-pages-hire-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-he-tsx": () => import("./../../../src/pages/index.he.tsx" /* webpackChunkName: "component---src-pages-index-he-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

